import React, { useRef } from "react";
import './BlogEditor.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JoditEditor from 'jodit-react';

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function BlogEditor() {
    const editor = useRef(null);
    const [content, setContent] = useState('');

    // const config = useMemo(
    //     {
    //         readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    //         placeholder: placeholder || 'Start typings...'
    //     },
    //     [placeholder]
    // );
    useEffect(() => {

    }, []);

    return (

        <div className="BlogEditor" style={{ minHeight:"80vh",maxWidth: "100%", margin: "0 auto" }}>
            <JoditEditor
                ref={editor}
                value={content}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}
            />
        </div>
    )
}

